import {Container, Row, Col, Button, Card} from 'react-bootstrap'
import FrontalDisplay from './frontaldisplay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVial, faHospitalUser, faStopwatch, faCalendar } from '@fortawesome/free-solid-svg-icons'
import {
    useHistory, useLocation,
} from "react-router-dom";
import "../styles/home.scss"
import {useEffect} from "react";


export default function Home(props) {
    let history = useHistory()

    function moreClickHandler(e) {
        const target_id = e.target.id.split("_")[1]
        pushToHistory(target_id)
    }

    function pushToHistory(target) {
        target = target === "home" ? "" : target
        history.push(target)
    }
    const loc = useLocation()
    useEffect(() => {
        props.setLoc(loc)
    }, [loc, props])

    const serviceCardMap = () => {
        return props.services.map(ser => {
            return ( <Col key={ser.name}>
                            <Card className={"service-home"}>
                                <Card.Body>
                                    <Card.Img border="light" variant="top"
                                          src={process.env.REACT_APP_API_LINK + '/media/' + ser.image}
                                style={{maxHeight: '200px', maxWidth: '200px'}}/>
                                    <Card.Text>
                                        <a href="https://www.googlg.com" className="no-style-link text-dark-blue">
                                            <b>Genereal Outpatient and Inpatient Care</b>
                                        </a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
            )
        })
    }
    return (
        <div>
            <FrontalDisplay />
            <div className="bg-white z-1">
                <Container className="z-1 bg-aqua text-center text-dark-blue pad-bottom-50 pad-top-50" id="About">
                    <h2 className="pad-30 text-dark-blue header-text">About Spring-Rose</h2>
                    <p className="text-dark-blue page-paragraph-text">
                        {props['SpringRoseAbout']}<br />
                    </p>
                    <Row xs={1} sm={1} md={2} lg={4} className="pad-30 pad-bottom-50">
                        <Col className="text-55">
                            <FontAwesomeIcon icon={faVial} />
                            <h6 className="text-dark-blue">On Site Lab and Testing</h6>
                        </Col>
                        <Col className="text-55">
                            <FontAwesomeIcon icon={faHospitalUser} />
                            <h6 className="text-dark-blue">Open 7 Days a Week</h6>
                        </Col>
                        <Col className="text-55">
                            <FontAwesomeIcon icon={faStopwatch} />
                            <h6 className="text-dark-blue">Short Waiting Times</h6>
                        </Col>
                        <Col className="text-55">
                            <FontAwesomeIcon icon={faCalendar} />
                            <h6 className="text-dark-blue">No Appointment Needed</h6>
                        </Col>
                    </Row>
                    <Button onClick={moreClickHandler} variant={"info"} id="_about">
                        Read More
                    </Button>
                </Container>
                <Container className="z-1 bg-aqua text-center text-dark-blue pad-top-50 pad-bottom-50" id="Services">
                    <h2 className="pad-30 text-dark-blue header-text">Our Services</h2>
                    <p className="text-dark-blue page-paragraph-text">
                        {props['ServicesAbout']}<br />
                    </p>
                    <Row xs={1} sm={1} md={2} lg={4} className="pad-30 pad-bottom-50 justify-content-center">
                        {serviceCardMap()}
                    </Row>
                    <Button onClick={moreClickHandler} variant={"info"} id="_services">
                        All Services
                    </Button>
                </Container>
            </div>
        </div>
    )
}