const api_link = process.env.REACT_APP_API_LINK

const makeLink = addOn => `${api_link}/${addOn}/`

export function getAbout(updateCaller, errorCaller) {
    const key = 'about'
    fetch(makeLink(key))
        .then(res => res.json())
        .then(data => {
            updateCaller(data[key])
        })
        .catch(err => {
            errorCaller(err)
        })
}

export function getHMO(updateCaller, errorCaller) {
    const key = 'hmos'
    fetch(makeLink(key))
        .then(res => res.json())
        .then(data => {
            updateCaller(data[key])
        })
        .catch(err => {
            errorCaller(err)
        })
}

export function getEmployees(updateCaller, errorCaller) {
    const key = 'employees'
    fetch(makeLink(key))
        .then(res => res.json())
        .then(data => {
            updateCaller(data[key])
        })
        .catch(err => {
            errorCaller(err)
        })
}

export function getServices(updateCaller, errorCaller) {
    const key = 'services'
    fetch(makeLink(key))
        .then(res => res.json())
        .then(data => {
            updateCaller(data[key])
        })
        .catch(err => {
            errorCaller(err)
        })
}

export function getContacts(updateCaller, errorCaller) {
    const key = 'contacts'
    fetch(makeLink(key))
        .then(res => res.json())
        .then(data => {
            updateCaller(data[key])
        })
        .catch(err => {
            errorCaller(err)
        })
}

export function getBanners(updateCaller, errorCaller) {
    const key = 'banners'
    fetch(makeLink(key))
        .then(res => res.json())
        .then(data => {
            updateCaller(data[key])
        })
        .catch(err => {
            errorCaller(err)
        })
}