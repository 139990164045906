import Home from './components/home';
import About from './components/about'
import Services from './components/services'
import Team from "./components/team";
import Partners from "./components/partners";
import Contact from './components/contact'
import MyNav from './components/nav'
import Footer from './components/footer'
import {
    BrowserRouter as Router,
    Switch,
    Route, useLocation,
} from 'react-router-dom';
import ScrollToTop from './components/scrolltop';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/generalthemes.scss'
import {useEffect, useState} from "react";
import ErrorCard from "./components/error_card";
import {getAbout, getBanners, getContacts, getEmployees, getHMO, getServices} from "./store";
import './styles/nav.scss'
import './styles/footer.scss'


function App() {
  let [loc, setLoc] = useState({pathname: '', search: '', hash: '', state: undefined, key: ''})
  const [error, setError] = useState("")
  const [about, setAbout] = useState({})
  const [contact, setContact] = useState({})
  const [services, setServices] = useState([])
  const [employees, setEmployees] = useState([])
  const [hmos, setHmos] = useState([])
  const [banners, setBanners] = useState([])
  const [m, setM] = useState(false)
  const errorCaller = (err) => {
    setError(err)
  }
  const aboutUpdateCaller = (data) => {
    setAbout(data)
  }
  const contactUpdateCaller = (data) => {
    setContact(data)
  }
  const serviceUpdateCaller = (data) => {
    setServices(data)
  }
  const employeesUpdateCaller = (data) => {
    setEmployees(data)
  }
  const hmosUpdateCaller = (data) => {
    setHmos(data)
  }
  const bannersUpdateCaller = (data) => {
    setBanners(data)
  }
  useEffect(() => {
      if(!m) {
          setM(true)
          getAbout(aboutUpdateCaller, errorCaller)
          getContacts(contactUpdateCaller, errorCaller)
          getServices(serviceUpdateCaller, errorCaller)
          getEmployees(employeesUpdateCaller, errorCaller)
          getHMO(hmosUpdateCaller, errorCaller)
          getBanners(bannersUpdateCaller, errorCaller)
      }
  }, [])
  return (
        error === '' ?
        <div className="App">
          <div className="z-0 repeat-back fixed-top" />
          <Router>
            <MyNav location={loc}/>
            <ScrollToTop />
            <Switch>
              <Route exact path="/">
                <Home SpringRoseAbout={about['SpringRoseAbout']} ServicesAbout={about['ServicesAbout']}
                      services={services} setLoc={setLoc}/>
              </Route>
              <Route exact path="/about">
                <About about={about['SpringRoseAbout']} employees={employees} hmos={hmos} setLoc={setLoc}/>
              </Route>
              <Route exact path="/team">
                <Team about={about['TeamAbout']} employees={employees} setLoc={setLoc}/>
              </Route>
              <Route exact path="/partners">
                <Partners about={about['PartnersAbout']} hmos={hmos} setLoc={setLoc}/>
              </Route>
              <Route exact path="/services">
                <Services about={about['ServicesAbout']} services={services} setLoc={setLoc}/>
              </Route>
              <Route exact path="/contact">
                <Contact contact={contact} setLoc={setLoc}/>
              </Route>
            </Switch>
            <Footer />
          </Router>
        </div>
          :
          <ErrorCard />
  );
}

export default App;
