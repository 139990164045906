import {Container, Row, Col, Card} from 'react-bootstrap'
import FrontalDisplay from './frontaldisplay'
import "../styles/about.scss"
import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export default function Partners(props) {
    const about = props.about
    const loc = useLocation()
    useEffect(() => {
        props.setLoc(loc)
    }, [loc, props])

    const hmoMap = () => {
        return props.hmos.map(hmo => {
            return (
                <Col  key={hmo.image}>
                    <Card border="light">
                        <Card.Body>
                             <Card.Img variant="top"
                                       style={{maxHeight: '100px', maxWidth: '100px'}}
                                  src={process.env.REACT_APP_API_LINK + '/media/' + hmo.image}/>
                            <Card.Title className={"text-left"}>Name: {hmo.name}</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
            )
        })
    }

    return (
        <div>
            <FrontalDisplay />
            <div className="bg-white z-1">
                <Container  className="z-1 bg-aqua pad-bottom-50" expand="xxl" id="OurTeam">
                    <h1 className="pad-50 w-75 text-dark-blue header-text">Our Partners
                        <b className="color-lemon">&#8228;</b></h1>
                    <p className="w-75 text-15 text-dark-blue page-paragraph-text">
                        {about}
                    </p>
                    <h1 className="pad-50 w-75 text-dark-blue header-text">Partners and Contractors
                    <b className="color-lemon">&#8228;</b></h1>
                    <Row sm={2} md={2} lg={3} className="text-15 text-dark-blue justify-content-center"
                    style={{overflow: 'scroll'}}>
                        {hmoMap()}
                    </Row>
                </Container>
            </div>
        </div>
    )
}