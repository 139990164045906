import Footer from './footer'
import {Container} from 'react-bootstrap'
import FrontalDisplay from './frontaldisplay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import {
    useLocation
  } from "react-router-dom";
import "../styles/contact.scss"
import {useEffect} from "react";

export default function Contact(props) {
    let loc = useLocation()
    useEffect(() => {
        props.setLoc(loc)
    }, [])
    const contact = props.contact ? props.contact[[0]] : null
    return (
        <div>
            <FrontalDisplay />
            <div className="bg-white z-1">
                <Container expand="xxl"  className="z-1 pad-top-50 pad-bottom-50 bg-aqua" id="ContactPageTop">
                    <h1 className="pad-10 w-75 text-dark-blue header-text">Contact Us<b className="color-lemon">&#8228;</b></h1>
                    <p className="w-75 text-15 text-dark-blue page-paragraph-text">
                        {contact? contact['memo']:''}
                    </p>
                </Container>
                <Container expand="xxl"  className="z-1 bg-aqua pad-bottom-50" id="OpeningHours">
                    <h1 className="pad-10 w-75 text-dark-blue header-text">Opening Hours</h1>
                    <p className="w-75 text-15 text-dark-blue page-paragraph-text">
                        Monday - Sunday: 24 Hours
                    </p>
                    <p className="w-75 text-15 text-dark-blue page-paragraph-text">
                       <span><FontAwesomeIcon icon={faPhoneAlt} />&emsp;|&emsp;{contact? contact['phone']:''}</span>
                    </p>
                    <p className="w-75 text-15 text-dark-blue page-paragraph-text">
                        <span><FontAwesomeIcon icon={faMailBulk} />&emsp;|&emsp;{contact? contact['email']:''} </span>
                    </p>
                </Container>
                <Container expand="xxl"  className="z-1 pad-bottom-50 bg-aqua" id="Directions">
                    <h1 className="pad-10 w-75 text-dark-blue header-text">Directions</h1>
                    <p className="w-75 text-15 text-dark-blue page-paragraph-text">
                        Street Address: {contact? contact['address']:''}
                    </p>
                    <p className="w-75 text-15 text-dark-blue page-paragraph-text">
                        City: {contact? contact['city']:''} &emsp; Zipcode: {contact? contact['zip']:''}
                    </p>
                    <p className="w-75 text-15 text-dark-blue page-paragraph-text">
                        Country: {contact? contact['country']:''}
                    </p>
                    <p className="w-75 text-15 text-dark-blue page-paragraph-text">
                        Tel: {contact? contact['phone']:''}
                    </p>
                </Container>
            </div>
        </div>
    )
}