import {Container, Row, Col, Card} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import FrontalDisplay from './frontaldisplay'
import "../styles/about.scss"
import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export default function Team(props) {
    const about = props.about
    const loc = useLocation()
    useEffect(() => {
        props.setLoc(loc)
    }, [loc, props])

    const employeeMap = (role) => {
        return typeof role !== "object" ? null : role.map(emp => {
            return (
                <Col className="text-center" key={emp.image}>
                    <Card border="light" className="scale-up" style={{ width: '18rem' }}>
                        <Card.Body>
                             <Card.Img variant="top" className="h-30 w-10"
                                  src={process.env.REACT_APP_API_LINK + '/media/' + emp.image}/>
                            <Card.Title className={'text-left'}>Name: {emp.name}</Card.Title>
                            <Card.Text className={'text-left'}>
                                Position: {emp['verbose_role']}{emp['isTraining'] ? '(Training)': null}
                            </Card.Text>
                            <Card.Text  className={'text-left'}>
                                {emp['facebook'] !== undefined && emp['facebook'] !== null
                                && emp['facebook'].length > 0 ?
                                 <a target="_blank" rel="noreferrer" className="text-dark-blue no-style-link"
                                   href={emp['facebook']}><FontAwesomeIcon icon={faFacebookF}/></a>
                                :
                                null}
                                {emp['linkedin'] !== undefined && emp['linkedin'] !== null
                                && emp['linkedin'].length > 0?
                                 <a target="_blank" rel="noreferrer" className="text-dark-blue no-style-link"
                                   href={emp['linkedin']}>&emsp;<FontAwesomeIcon icon={faLinkedin} /></a>
                                :
                                null}
                                {emp['email'] !== undefined && emp['email'] !== null
                                && emp['email'].length > 0?
                                 <a target="_blank" rel="noreferrer" className="text-dark-blue no-style-link"
                                   href={emp.email}>&emsp;<FontAwesomeIcon icon={faMailBulk} /></a>
                                :
                                null}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            )
        })
    }

    return (
        <div>
            <FrontalDisplay />
            <div className="bg-white z-1">
                <Container  className="z-1 bg-aqua pad-bottom-50" expand="xxl" id="OurTeam">
                    <h1 className="pad-50 w-75 text-dark-blue header-text">Our Team
                        <b className="color-lemon">&#8228;</b></h1>
                    <p className="w-75 text-15 text-dark-blue page-paragraph-text">
                        {about}
                    </p>
                    <h1 className="pad-50 w-75 text-dark-blue header-text">MEET THE TEAM
                        <b className="color-lemon">&#8228;</b></h1>
                    <div className="text-15 text-dark-blue">
                        <h2 className="pad-50 w-75 text-dark-blue header-text">Executive Board
                        <b className="color-lemon">&#8228;</b></h2>
                        <Row sm={2} md={2} lg={3} className="text-15 text-dark-blue justify-content-center"
                    style={{overflow: 'scroll'}}>
                            {employeeMap(props.employees['executives'])}
                        </Row>
                    </div>
                    <div className="text-15 text-dark-blue">
                        <h2 className="pad-50 w-75 text-dark-blue header-text">Physicians(MD)
                        <b className="color-lemon">&#8228;</b></h2>
                        <Row sm={2} md={2} lg={3} className="text-15 text-dark-blue justify-content-center"
                    style={{overflow: 'scroll'}}>
                            {employeeMap(props.employees['Physician(MD)'])}
                        </Row>
                    </div>
                    <div className="text-15 text-dark-blue">
                        <h2 className="pad-50 w-75 text-dark-blue header-text">Nursing Staff
                        <b className="color-lemon">&#8228;</b></h2>
                        <Row sm={2} md={2} lg={3} className="text-15 text-dark-blue justify-content-center"
                    style={{overflow: 'scroll'}}>
                            {employeeMap(props.employees['Nursing Staff'])}
                        </Row>
                    </div>
                    <div className="text-15 text-dark-blue">
                         <h2 className="pad-50 w-75 text-dark-blue header-text">Other Employees
                         <b className="color-lemon">&#8228;</b></h2>
                        <Row sm={2} md={2} lg={3} className="text-15 text-dark-blue justify-content-center"
                    style={{overflow: 'scroll'}}>
                            {employeeMap(props.employees['Other Staff'])}
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    )
}