import MyNav from './nav'
import Footer from './footer'
import {Container, Row, Col} from 'react-bootstrap'
import "../styles/services.scss"
import FrontalDisplay from './frontaldisplay'
import {
    useLocation
  } from "react-router-dom";
import { Divider } from 'antd';
import 'antd/dist/antd.css';
import {useEffect} from "react";

export default function Services(props) {
    const loc = useLocation()
    const about = props.about
    useEffect(() => {
        props.setLoc(loc)
    }, [loc, props])

    const mapServices = () => {
        return props.services.map(ser => {
            return (
                <div key={ser.name}>
                    <div className="w-75"><Divider className="bg-dark-blue"/></div>
                    <Row className="w-75 text-dark-blue" sm={2}>
                        <Col>{ser.name}</Col>
                        <Col className="text-right">{ser.cost}</Col>
                    </Row>
                </div>
            )
        })
    }
    return (
        <div>
            <MyNav location={loc}/>
            <FrontalDisplay />
            <div className="bg-white z-1">
                <Container expand="xxl"  className="z-1 pad-top-50 pad-bottom-50 bg-aqua" id="ServicePageTop">
                    <h1 className="pad-10 w-75 text-dark-blue">Our Services<b className="color-lemon">&#8228;</b></h1>
                    <p className="w-75 text-15 text-dark-blue">
                        {about}
                    </p>
                </Container>
                <Container className="z-1 bg-aqua pad-bottom-50" expand="xxl" id="ServiceList">
                    <h1 className="pad-10 w-75 text-dark-blue">Services</h1>
                    {mapServices()}
                    <div className="w-75"><Divider className="bg-dark-blue"/></div>
                </Container>
            </div>
            <Footer />
        </div>
    )
}